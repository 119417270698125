import { useEditor, useValue, useIsDarkMode } from "@tldraw/tldraw";

const CustomGrid = ({ gridSize }) => {
  const editor = useEditor();
  const showGrid = editor.getInstanceState().isGridMode;

  // Only render the grid if `isGridMode` is true
  const bounds = useValue(
    "viewportPageBounds",
    () => editor.getViewportPageBounds(),
    [editor]
  );
  const zoom = useValue("zoomValue", () => editor.getZoomLevel(), [editor]);
  const isDarkMode = useIsDarkMode();

  // Calculate grid lines based on zoom and viewport bounds
  const adjustedGridSize = gridSize * zoom * 50;
  const startX = Math.floor(bounds.minX / adjustedGridSize) * adjustedGridSize;
  const startY = Math.floor(bounds.minY / adjustedGridSize) * adjustedGridSize;
  const endX = Math.ceil(bounds.maxX / adjustedGridSize) * adjustedGridSize;
  const endY = Math.ceil(bounds.maxY / adjustedGridSize) * adjustedGridSize;

  // Generate grid lines as an array of SVG `<line>` elements
  const lines = [];

  for (let x = startX; x <= endX; x += adjustedGridSize) {
    const lineX = (x - bounds.minX) * zoom;
    lines.push(
      <line
        key={`v-${x}`}
        x1={lineX}
        y1={0}
        x2={lineX}
        y2="100%"
        stroke={isDarkMode ? "#555" : "#BBB"}
        strokeWidth={1}
      />
    );
  }

  for (let y = startY; y <= endY; y += adjustedGridSize) {
    const lineY = (y - bounds.minY) * zoom;
    lines.push(
      <line
        key={`h-${y}`}
        x1={0}
        y1={lineY}
        x2="100%"
        y2={lineY}
        stroke={isDarkMode ? "#555" : "#BBB"}
        strokeWidth={1}
      />
    );
  }

  return (
    showGrid && (
      <svg
        className="tl-grid"
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        {lines}
      </svg>
    )
  );
};

export default CustomGrid;
