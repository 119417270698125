import {
  BaseBoxShapeUtil,
  HTMLContainer,
  T,
  DefaultColorStyle,
  DefaultSizeStyle,
  DefaultFontStyle,
  useEditor,
  Rectangle2d,
} from "tldraw";
import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";

export class CustomTextUtil extends BaseBoxShapeUtil {
  static type = "custom-text";

  static props = {
    text: T.string,
    color: T.string,
    size: T.string,
    align: T.string,
    font: T.string,
    bold: T.boolean,
    italic: T.boolean,
    autoSize: T.boolean,
    w: T.number,
    h: T.number,
    textAlign: T.string,
    fontSize: T.string,
  };

  getDefaultProps() {
    return {
      text: "# Math and Markdown Example\n\nRegular **markdown** works!\n\nInline math: $E = mc^2$\n\nBlock math:\n$$\n\\frac{-b \\pm \\sqrt{b^2-4ac}}{2a}\n$$",
      color: DefaultColorStyle.defaultValue,
      size: DefaultSizeStyle.defaultValue,
      align: "start",
      font: DefaultFontStyle.defaultValue,
      bold: false,
      italic: false,
      autoSize: false,
      w: 200,
      h: 100,
      textAlign: "start",
      fontSize: "24px",
    };
  }

  component(shape) {
    const TextComponent = () => {
      const editor = useEditor();
      const textRef = useRef(null);
      const { text, color, size, font, align } = shape.props;
      const [isModalOpen, setModalOpen] = useState(false);
      const [tempText, setTempText] = useState(text);
      const modalRef = useRef(null);
      const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
      const minModalWidth = 200;
      const minModalHeight = 2;
      const [originalZIndex, setOriginalZIndex] = useState(0);

      const setZIndex = useCallback(() => {
        const tlShape = textRef.current.parentElement;
        setOriginalZIndex(tlShape.style.zIndex);
        tlShape.style.zIndex = 999999;
      }, [textRef]);
      
      const resetZIndex = useCallback(() => {
        var tlShape = null;
        if (!modalRef.current) {
          tlShape = textRef.current.parentElement;
        } else {
          tlShape = modalRef.current.parentElement.parentElement;
        }
        tlShape.style.zIndex = originalZIndex;
      }, [textRef, modalRef, originalZIndex]);

      const openModal = useCallback(() => {
        setZIndex();

        setTempText(text);
        setModalOpen(true);

        // // Temporarily disable all tools except text selection
        // editor.updateInstanceState({
        //   isToolLocked: true,
        // });

        setModalPosition({
          top: 0,
          left: 0,
          width: Math.max(shape.props.w, minModalWidth),
          height: Math.max(shape.props.h, minModalHeight),
        });
      }, [textRef]);

      const saveText = () => {
        editor.updateShapes([
          {
            id: shape.id,
            type: "custom-text",
            props: { ...shape.props, text: tempText },
          },
        ]);
        closeModal();
      };

      const closeModal = useCallback(() => {
        // Reset z-index
        resetZIndex();

        setModalOpen(false);
        // Re-enable tools
        editor.updateInstanceState({
          isToolLocked: false,
        });
      }, [modalRef, originalZIndex]);

      useEffect(() => {
        const handleClickOutside = (event) => {
          if (modalRef.current && !modalRef.current.contains(event.target)) {
            const container = event.target.closest(".tl-html-container");
            if (!container || !container.contains(modalRef.current)) {
              closeModal();
            }
          }
        };
        const modalElement = modalRef.current;
        const preventDefault = (e) => {
          e.stopPropagation();
        };

        if (modalElement) {
          if (isModalOpen) {
            document.addEventListener("mousedown", handleClickOutside);

            modalElement.addEventListener("pointerdown", preventDefault, true);
            modalElement.addEventListener("pointermove", preventDefault, true);
            modalElement.addEventListener("pointerup", preventDefault, true);
          } else {
              document.removeEventListener("mousedown", handleClickOutside);
              modalElement.removeEventListener(
                "pointerdown",
                preventDefault,
                true
              );
              modalElement.removeEventListener(
                "pointermove",
                preventDefault,
                true
              );
              modalElement.removeEventListener(
                "pointerup",
                preventDefault,
                true
              );
          }
        }
      }, [isModalOpen]);

      const handleTextChange = (e) => setTempText(e.target.value);
      const [isHovered, setIsHovered] = useState(false);

      return (
        <>
          {!isModalOpen && (
            <div
              ref={textRef}
              onMouseEnter={() => {setZIndex(); setIsHovered(true)}}
              onMouseLeave={() => {setIsHovered(false); if (!isModalOpen) {resetZIndex();}}}
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "12px",
                color: color,
                fontSize:
                  shape.props.fontSize ||
                  (size === "xl"
                    ? "48px"
                    : size === "l"
                    ? "24px"
                    : size === "m"
                    ? "16px"
                    : "12px"),
                fontFamily:
                  font === "serif"
                    ? "serif"
                    : font === "mono"
                    ? "monospace"
                    : "sans-serif",
                pointerEvents: "auto",
                overflow: "visible",
              }}
            >
              {/* <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  padding: "4px",
                  background: "white",
                  borderRadius: "4px",
                  opacity: isHovered ? 1 : 0,
                  transition: "opacity 0.2s",
                  zIndex: 1000,
                }}
              > */}
                <button
                  style={{
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    opacity: isHovered ? 1 : 0,
                    transition: "opacity 0.2s",
                    background: "#f5f5f5",
                    borderRadius: "4px",
                    padding: "6px 16px", // Slightly increased padding
                    border: "1px solid #d0d0d0", // Darker border
                    boxShadow: "0 2px 4px rgba(0,0,0,0.15)", // More pronounced shadow
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 2,
                    fontSize: "13px", // Slightly larger font
                    color: "#444", // Darker text color
                    fontFamily: "system-ui, -apple-system, sans-serif",
                    fontWeight: 500, // Medium weight for better visibility
                    pointerEvents: isHovered ? "auto" : "none",
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault(); // Prevent any default behavior
                    e.stopPropagation(); // Stop event propagation
                    openModal();
                  }}
                >
                  Edit
                </button>
              {/* </div> */}
              <div style={{ width: "100%" }}>
                <ReactMarkdown
                  remarkPlugins={[remarkMath]}
                  rehypePlugins={[rehypeKatex]}
                >
                  {text}
                </ReactMarkdown>
              </div>
            </div>
          )}
          {isModalOpen && (
            <Modal
              ref={modalRef}
              text={tempText}
              onChange={handleTextChange}
              onSave={saveText}
              onCancel={closeModal}
              width={modalPosition.width}
              height={modalPosition.height}
              position={modalPosition}
            />
          )}
        </>
      );
    };

    return <TextComponent />;
  }

  indicator(shape) {
    return (
      <rect
        width={shape.props.w}
        height={shape.props.h}
        fill="none"
        stroke="black"
        strokeWidth={1}
      />
    );
  }
}

const Modal = React.forwardRef(
  ({ text, onChange, onSave, onCancel, width, height, position }, ref) => {

    const handleTextareaPointerEvents = (e) => {
      e.stopPropagation();
      // Allow default browser text selection behavior
    };

    return (
      <HTMLContainer
        className="modal-container"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "#f8f9fa",
          padding: "8px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          border: "none",
          borderRadius: "6px",
          boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
          pointerEvents: "all",
          // Prevent TLDraw selection rectangle
          userSelect: "none",
        }}
      >
        <div
          ref={ref}
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            zIndex: 999999,
          }}
          onPointerDown={handleTextareaPointerEvents}
          onPointerMove={handleTextareaPointerEvents}
          onPointerUp={handleTextareaPointerEvents}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              minHeight: 0,
            }}
          >
              <textarea
                value={text}
                onChange={onChange}
                placeholder="Enter Markdown and LaTeX math: inline ($...$) and block ($$...$$)"
                style={{
                  width: "100%",
                  flex: 1,
                  resize: "none",
                  padding: "8px",
                  fontSize: "14px",
                  fontFamily: "ui-monospace, SFMono-Regular, Menlo, monospace",
                  lineHeight: "1.6",
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  outline: "none",
                  boxSizing: "border-box",
                  position: "relative",
                  background: "#fcfcfc",
                  minHeight: 0,
                  userSelect: "text", // Enable text selection
                }}
                onPointerDown={(e) => {
                  e.stopPropagation();
                }}
              />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              backgroundColor: "f8f9fa",
              gap: "4px",
              marginTop: "4px", // Reduced margin
              fontSize: "12px",
            }}
          >
            <span style={{ color: "#888", marginRight: "auto" }}>
              Markdown & LaTeX: $...$, $$...$$
            </span>
            <button
              onClick={onCancel}
              style={{
                padding: "4px 8px",
                fontSize: "12px",
                backgroundColor: "white",
                color: "#666",
                border: "1px solid #e0e0e0",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
            <button
              onClick={onSave}
              style={{
                padding: "4px 8px",
                fontSize: "12px",
                backgroundColor: "#2563eb",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Save
            </button>
          </div>
        </div>
      </HTMLContainer>
    );
  }
);

export class SnappingUtil extends BaseBoxShapeUtil {
  static type = "Custom";
  static props = {
    w: T.number,
    h: T.number,
    suit: T.string,
  };

  isAspectRatioLocked(shape) {
    return true;
  }

  getDefaultProps() {
    const cardSuitsArray = ["♠️", "♣️", "♥️", "♦️"];
    const randomSuit =
      cardSuitsArray[Math.floor(Math.random() * cardSuitsArray.length)];
    return {
      w: 270,
      h: 370,
      suit: randomSuit,
    };
  }

  // Define the snapping geometry for the shape
  getBoundsSnapGeometry(shape) {
    return new Rectangle2d({
      width: shape.props.h / 4.5,
      height: shape.props.h / 4.5,
      isFilled: true,
    });
  }

  component(shape) {
    return (
      <HTMLContainer
        style={{
          height: shape.props.h,
          width: shape.props.w,
          backgroundColor: "white",
          boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 8,
        }}
        id={shape.id}
      >
        <span
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: shape.props.h / 4.5,
            width: shape.props.h / 4.5,
            fontSize: shape.props.h / 5,
          }}
        >
          {shape.props.suit}
        </span>
        <div style={{ fontSize: shape.props.h / 3 }}>{shape.props.suit}</div>
      </HTMLContainer>
    );
  }

  // Render the indicator for the shape
  indicator(shape) {
    return <rect width={shape.props.w} height={shape.props.h} />;
  }
}

export class CustomImageUtil extends BaseBoxShapeUtil {
  static type = "custom-image";

  static props = {
    assetId: T.string,
    w: T.number,
    h: T.number,
  };

  getDefaultProps() {
    return {
      assetId: "",
      w: 200,
      h: 200,
    };
  }

  component(shape) {
    const ImageComponent = () => {
      const editor = useEditor();
      const [isHovered, setIsHovered] = useState(false);
      const imageRef = useRef(null);

      const handleImageUpload = async (e) => {
        const file = e.target.files?.[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = async () => {
            const dataUrl = reader.result;

            // Create temporary image to get dimensions
            const img = new Image();
            img.src = dataUrl;

            img.onload = () => {
              const { naturalWidth, naturalHeight } = img;
              const aspectRatio = naturalWidth / naturalHeight;

              // Maintain current width but adjust height by aspect ratio
              const newWidth = shape.props.w;
              const newHeight = newWidth / aspectRatio;

              // Create new asset
              const newAssetId = `asset:${Date.now()}`;
              editor.createAssets([
                {
                  id: newAssetId,
                  type: "image",
                  typeName: "asset",
                  props: {
                    name: file.name,
                    src: dataUrl,
                    w: newWidth,
                    h: newHeight,
                    mimeType: file.type,
                    isAnimated: false,
                  },
                  meta: null,
                },
              ]);

              // Update shape with new asset
              editor.updateShape({
                id: shape.id,
                type: "custom-image",
                props: {
                  assetId: newAssetId,
                  w: newWidth,
                  h: newHeight,
                },
              });
            };
          };
          reader.readAsDataURL(file);
        }
      };

      const asset = editor.getAsset(shape.props.assetId);

      return (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            pointerEvents: "all",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onDoubleClick={(e) => {
            // e.preventDefault();
            // e.stopPropagation();
            editor.cancel();
          }}
        >
          {asset && (
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "100%",
                pointerEvents: "none",
              }}
            >
              <img
                ref={imageRef}
                src={asset.props.src}
                alt="Custom shape"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  pointerEvents: "none",
                }}
              />
            </div>
          )}
          {isHovered && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                opacity: 1,
                transition: "opacity 0.2s",
                background: "rgba(255, 255, 255, 0.9)",
                borderRadius: "50%",
                padding: "8px",
                border: "2px solid #d0d0d0",
                boxShadow: "0 2px 4px rgba(0,0,0,0.15)",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 999999,
                pointerEvents: "all",
                width: "40px",
                height: "40px",
                minWidth: "40px",
                minHeight: "40px",
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                const input = document.createElement("input");
                input.type = "file";
                input.accept = "image/*";
                input.onchange = handleImageUpload;
                input.click();
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10" stroke="#666" fill="none" />
                <path d="M12 8v8M8 12h8" stroke="#666" />
              </svg>
            </div>
          )}
        </div>
      );
    };

    return <ImageComponent />;
  }

  indicator(shape) {
    return (
      <rect
        width={shape.props.w}
        height={shape.props.h}
        fill="none"
        stroke="black"
        strokeWidth={1}
      />
    );
  }
}

export default CustomImageUtil;
