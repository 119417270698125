import styled, { keyframes, createGlobalStyle } from "styled-components";

export const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    min-height: 100vh;
    background: #0a192f; /* Same as PageWrapper start color */
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;

export const PageWrapper = styled.div`
  flex: 1;
  width: 100%;
  background: linear-gradient(135deg, #0a192f 0%, #20465e 50%, #2d767f 100%);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 15s ease infinite;
  display: flex;
  color: white;
  margin: 0;
  padding: 0;
  position: relative;

  @media (max-width: 1200px) {
    padding: 2rem 0;
  }
`;

export const ContentContainer = styled.div`
  padding: 8% 10%;
  width: 100%;
  position: relative;
  z-index: 1;

  @media (max-width: 1200px) {
    padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const Title = styled.h1`
  font-size: min(4.5rem, 8vw);
  max-width: 800px;
  margin-bottom: 2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 1;
`;

export const AnimationPlaceholder = styled.div`
  width: min(700px, 45vw);
  height: min(700px, 45vw);
  position: absolute;
  right: 1%;
  top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @media (max-width: 1200px) {
    width: min(500px, 90vw);
    height: min(500px, 90vw);
    position: static;
    margin: 0 auto;
    order: -1;
    padding: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
  position: relative;

  @media (max-width: 1200px) {
    margin: 0 auto;
  }
`;

export const Subtitle = styled.div`
  font-size: min(1.3rem, 4vw);
  font-family: "Inter", sans-serif;
  opacity: 0.5;
  color: #ffffff;
  max-width: 800px;
  line-height: 1.5;

  @media (max-width: 1200px) {
    margin: 0 auto;
  }
`;

export const SubtitleWrapper = styled.div`
  margin-bottom: 3rem;
`;

export const Input = styled.input`
  padding: 1.5rem 2rem;
  border: 1px solid
    ${({ hasError }) => (hasError ? "red" : "rgba(255, 255, 255, 0.1)")};
  border-radius: 100px;
  font-size: 1.2rem;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  color: white;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${({ hasError }) =>
      hasError ? "red" : "rgba(255, 255, 255, 0.3)"};
    background: rgba(255, 255, 255, 0.1);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export const Button = styled.button`
  padding: 1rem 2rem;
  border: none;
  border-radius: 50px;
  background: linear-gradient(135deg, #64b5f6 0%, #4db6ac 100%);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  width: fit-content;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(77, 182, 172, 0.3);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const EditorLayout = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #f0f0f0;
`;

export const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  color: white;
  background: linear-gradient(135deg, #0a192f 0%, #20465e 50%, #2d767f 100%);
  background-size: 200% 200%;
  animation: gradientAnimation 15s ease infinite;
  padding: 2rem;
`;

export const SuccessMessage = styled.h2`
  font-size: min(3rem, 6vw);
  font-weight: 700;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
  text-align: center;
`;

export const SuccessText = styled.p`
  font-size: 1.2rem;
  opacity: 0.8;
  font-family: "Inter", sans-serif;
  max-width: 600px;
  text-align: center;
  line-height: 1.5;
  margin-top: 0;
`;

export const SuccessButton = styled.button`
  padding: 1rem 2rem;
  margin-top: 2rem;
  border: none;
  border-radius: 50px;
  background: linear-gradient(135deg, #64b5f6 0%, #4db6ac 100%);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  font-family: "Poppins", sans-serif;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(77, 182, 172, 0.3);
  }
`;

export const ErrorMessage = styled.div`
color: red;
margin-top: 8px;
font-size: 14px;
`;