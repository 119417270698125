import React, { useState, Suspense, useEffect } from "react";
import PosterEditor from "./components/PosterEditor";
import axios from "axios";
import "./App.css";
import Scene from "./animation";
import { PostHogProvider } from "posthog-js/react";
import {
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import {backendUrl} from "./constants";
import * as Styled from "./style"

function SuccessPage() {
  return (
    <Styled.SuccessContainer>
      <Styled.SuccessMessage>We received your request and we are working on it!</Styled.SuccessMessage>
      <Styled.SuccessText>
        You will receive an email with the link once the poster is ready.
      </Styled.SuccessText>
      <Styled.SuccessButton onClick={() => (window.location.href = "/")}>
        Go Back to Home
      </Styled.SuccessButton>
    </Styled.SuccessContainer>
  );
}

function PosterEditorWithFetch() {
  const { id } = useParams();
  const [posterData, setPosterData] = useState(null);

  useEffect(() => {
    async function fetchPosterData() {
      try {
        const response = await axios.get(`${backendUrl}/poster/${id}`);
        setPosterData(response.data);
      } catch (error) {
        console.error("Error fetching poster data:", error);
      }
    }
    fetchPosterData();
  }, [id]);

  if (posterData) {
    return (
      <Styled.EditorLayout>
        <PosterEditor poster={posterData} />
      </Styled.EditorLayout>
    );
  }

  return <div>Loading poster...</div>;
}

function App() {
  const [urlInput, setUrlInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateUrl = (url) => {
    const arxivRegex = /^(https:\/\/arxiv\.org\/(abs|pdf)\/\d{4}\.\d{4,5})(\.pdf)?$/;
    return arxivRegex.test(url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      axios.post(`${backendUrl}/process_paper`, {
        url: urlInput,
        email: emailInput,
      });
      navigate(`/success`);
    } catch (error) {
      console.error("Error processing paper:", error);
      // setErrorMessage("An error occurred while processing your request. Please try again.");
    }
  };

  return (
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}>
      <Styled.GlobalStyle />
      <Routes>
        <Route
          path="/"
          element={
            <Styled.PageWrapper>
              <Styled.ContentContainer>
                <Styled.Title>Turn your paper into a beautiful poster.</Styled.Title>
                <Styled.SubtitleWrapper>
                  <Styled.Subtitle>
                    Transform your research into engaging visual presentations.
                  </Styled.Subtitle>
                </Styled.SubtitleWrapper>
                <Styled.Form onSubmit={handleSubmit}>
                  <Styled.Input
                    type="text"
                    value={urlInput}
                    onChange={(e) => {
                      setUrlInput(e.target.value);
                    }}
                    placeholder="Enter arXiv paper URL"
                    hasError={!validateUrl(urlInput)&& urlInput != ""}
                  />
                  {(!validateUrl(urlInput) && urlInput != "") && <Styled.ErrorMessage>{"Please enter a valid arXiv URL (e.g., https://arxiv.org/abs/1234.56789)."}</Styled.ErrorMessage>}
                  <Styled.Input
                    type="text"
                    value={emailInput}
                    onChange={(e) => {
                      setEmailInput(e.target.value);
                    }}
                    placeholder="Enter your email address"
                    hasError={!validateEmail(emailInput) && emailInput != ""}
                  />
                  {(!validateEmail(emailInput) && emailInput != "") && <Styled.ErrorMessage>{"Please enter a valid email address."}</Styled.ErrorMessage>}
                  <Styled.Button type="submit" disabled={!validateEmail(emailInput) || !validateUrl(urlInput)}>
                    Generate Poster
                  </Styled.Button>
                </Styled.Form>
              </Styled.ContentContainer>
              <Styled.AnimationPlaceholder>
                <Suspense fallback={<div>Loading...</div>}>
                  <Scene />
                </Suspense>
              </Styled.AnimationPlaceholder>
            </Styled.PageWrapper>
          }
        />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/poster/:id" element={<PosterEditorWithFetch />} />
      </Routes>
    </PostHogProvider>
  );
}

export default App;
