import React, { useRef, Suspense, useEffect, useState } from "react";
import { Canvas, useFrame, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";

const SinglePaper = ({ phase = 0, xOffset = 0 }) => {
  const meshRef = useRef();
  const texture = useLoader(TextureLoader, "/paper.png");
  const [scale, setScale] = useState([1, 1, 1]);

  useEffect(() => {
    if (texture) {
      const ratio = texture.image.width / texture.image.height;
      if (ratio > 1) {
        setScale([1.8, 1.8 / ratio, 1]);
      } else {
        setScale([1.8 * ratio, 1.8, 1]);
      }
    }
  }, [texture]);

  useFrame((state) => {
    const time = state.clock.getElapsedTime();
    const offsetTime = time + phase;

    // Bounce movement with offset
    meshRef.current.position.y = Math.sin(offsetTime * 2) * 0.2;

    // Gentle wave rotation with offset
    meshRef.current.rotation.y = Math.sin(offsetTime * 1.5) * 0.2;

    // Very subtle tilt with offset
    meshRef.current.rotation.x = Math.sin(offsetTime * 1.2) * 0.02;
    meshRef.current.rotation.z = Math.cos(offsetTime * 1.2) * 0.02;

    // Fixed horizontal offset
    meshRef.current.position.x = xOffset;
  });

  return (
    <mesh ref={meshRef} scale={scale}>
      <planeGeometry args={[1, 1]} />
      <meshStandardMaterial map={texture} transparent opacity={1} side={2} />
    </mesh>
  );
};

const LoadingFallback = () => {
  return (
    <mesh>
      <planeGeometry args={[1, 1]} />
      <meshStandardMaterial color="#ffffff" />
    </mesh>
  );
};

const Scene = () => {
  return (
    <Canvas
      camera={{
        position: [0, 0, 5],
        fov: 40,
      }}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <ambientLight intensity={0.7} />
      <pointLight position={[10, 10, 10]} intensity={0.5} />
      <pointLight position={[-10, -10, -10]} intensity={0.3} />
      <Suspense fallback={<LoadingFallback />}>
        <SinglePaper phase={0.33} xOffset={0} />
      </Suspense>
    </Canvas>
  );
};

export default Scene;
