import React from "react";
import {
  DefaultMainMenu,
  DefaultColorStyle,
  TldrawUiMenuGroup,
  TldrawUiMenuItem,
  TldrawUiMenuSubmenu,
  TldrawUiMenuActionItem,
  useEditor,
  getSnapshot,
} from "tldraw";
import axios from "axios";
import { backendUrl } from "../constants";

// Utility hooks from the source code
const useCanRedo = () => {
  const editor = useEditor();
  return editor.getCanRedo();
};

const useCanUndo = () => {
  const editor = useEditor();
  return editor.getCanUndo();
};

function EditSubmenu() {
  const editor = useEditor();
  const selectToolActive = editor.getCurrentToolId() === "select";

  return (
    <TldrawUiMenuSubmenu
      id="edit"
      label="menu.edit"
      disabled={!selectToolActive}
    >
      <UndoRedoGroup />
      <MiscMenuGroup />
      <LockGroup />
      <TldrawUiMenuGroup id="select-all">
        <TldrawUiMenuActionItem actionId="select-all" />
      </TldrawUiMenuGroup>
    </TldrawUiMenuSubmenu>
  );
}

function MiscMenuGroup() {
  return (
    <TldrawUiMenuGroup id="misc">
      <TldrawUiMenuActionItem actionId="group" />
      <TldrawUiMenuActionItem actionId="ungroup" />
      <TldrawUiMenuActionItem actionId="edit-link" />
    </TldrawUiMenuGroup>
  );
}

function LockGroup() {
  return (
    <TldrawUiMenuGroup id="lock">
      <TldrawUiMenuActionItem actionId="toggle-lock" />
      <TldrawUiMenuActionItem actionId="unlock-all" />
    </TldrawUiMenuGroup>
  );
}

function UndoRedoGroup() {
  const canUndo = useCanUndo();
  const canRedo = useCanRedo();
  return (
    <TldrawUiMenuGroup id="undo-redo">
      <TldrawUiMenuActionItem actionId="undo" disabled={!canUndo} />
      <TldrawUiMenuActionItem actionId="redo" disabled={!canRedo} />
    </TldrawUiMenuGroup>
  );
}

function ViewSubmenu() {
  return (
    <TldrawUiMenuSubmenu id="view" label="menu.view">
      <TldrawUiMenuGroup id="view-actions">
        <TldrawUiMenuActionItem actionId="zoom-in" />
        <TldrawUiMenuActionItem actionId="zoom-out" />
        <TldrawUiMenuActionItem actionId="zoom-to-100" />
        <TldrawUiMenuActionItem actionId="zoom-to-fit" />
        <TldrawUiMenuActionItem actionId="zoom-to-selection" />
      </TldrawUiMenuGroup>
    </TldrawUiMenuSubmenu>
  );
}

function ExtrasGroup() {
  const editor = useEditor();

  // Canvas-specific action to add text shape
  const addCustomTextShape = () => {
    const shapeId = `shape::custom-text-${Date.now()}`;
    const x = 50;
    const y = 50;

    editor.createShape({
      id: shapeId,
      type: "custom-text",
      x: x,
      y: y,
      parentId: editor.currentPageId,
      props: {
        text: "Insert text here",
        fontSize: "16px", // Smaller default font size
        color: DefaultColorStyle.defaultValue,
        align: "middle",
        autoSize: false,
        w: 200, // Adjusted width for smaller font size
        font: "serif",
        bold: false,
        italic: false,
      },
    });
  };

  const handleCustomImageInsert = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (e) => {
      const file = e.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async () => {
          const dataUrl = reader.result;
          const img = new Image();
          img.src = dataUrl;

          img.onload = () => {
            const { naturalWidth, naturalHeight } = img;
            const aspectRatio = naturalWidth / naturalHeight;

            // Default size for new images
            const defaultWidth = 300;
            const height = defaultWidth / aspectRatio;

            // Create asset
            const newAssetId = `asset:${Date.now()}`;
            editor.createAssets([
              {
                id: newAssetId,
                type: "image",
                typeName: "asset",
                props: {
                  name: file.name,
                  src: dataUrl,
                  w: defaultWidth,
                  h: height,
                  mimeType: file.type,
                  isAnimated: false,
                },
                meta: null,
              },
            ]);

            // Create custom image shape
            editor.createShape({
              id: `shape:${Date.now()}`,
              type: "custom-image",
              x: 200 - defaultWidth / 2,
              y: 200 - height / 2,
              props: {
                assetId: newAssetId,
                w: defaultWidth,
                h: height,
              },
            });
          };
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  return (
    <TldrawUiMenuGroup id="extras">
      <TldrawUiMenuItem
        id="insert-custom-text"
        label="Insert text"
        icon="text"
        onSelect={addCustomTextShape}
      />
      <TldrawUiMenuItem
        id="insert-custom-image"
        label="Insert image"
        icon="image"
        onSelect={handleCustomImageInsert}
      />
    </TldrawUiMenuGroup>
  );
}

function PreferencesGroup() {
  return (
    <TldrawUiMenuActionItem actionId="toggle-grid" />
    // <TldrawUiMenuGroup id="preferences">
    //   <TldrawUiMenuSubmenu id="preferences" label="menu.preferences">
    //     <TldrawUiMenuGroup id="preferences-actions">
    //       <TldrawUiMenuActionItem actionId="toggle-snap-mode" />
    //       <TldrawUiMenuActionItem actionId="toggle-tool-lock" />
    //       <TldrawUiMenuActionItem actionId="toggle-grid" />
    //       <TldrawUiMenuActionItem actionId="toggle-wrap-mode" />
    //       <TldrawUiMenuActionItem actionId="toggle-focus-mode" />
    //       <TldrawUiMenuActionItem actionId="toggle-reduce-motion" />
    //       <TldrawUiMenuActionItem actionId="toggle-dark-mode" />
    //     </TldrawUiMenuGroup>
    //   </TldrawUiMenuSubmenu>
    //   <TldrawUiMenuActionItem actionId="keyboard-shortcuts" />
    // </TldrawUiMenuGroup>
  );
}

function MainMenuContent() {
  return (
    <>
      <EditSubmenu />
      <ViewSubmenu />
      <ExtrasGroup />
      <PreferencesGroup />
    </>
  );
}

const CustomMainMenu = () => {
  const editor = useEditor();

  const handleSave = () => {
    const snapshot = getSnapshot(editor.store);
    console.log(snapshot);
    const url = window.location.href;
    const posterId = url.split("/").pop();
    try {
      axios.post(`${backendUrl}/poster/${posterId}`, {
        poster_data: snapshot,
      });
    } catch (error) {
      console.error("Error saving poster:", error);
    }
  };

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "s") {
        event.preventDefault();
        handleSave();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <DefaultMainMenu>
      <TldrawUiMenuGroup id="main">
        <TldrawUiMenuItem
          id="save"
          label="Save"
          icon="image"
          onSelect={handleSave}
          kbd="s"
        />
      </TldrawUiMenuGroup>
      <MainMenuContent />
    </DefaultMainMenu>
  );
};

export default CustomMainMenu;
