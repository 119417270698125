import { useState } from "react";
import { exportToBlob } from "@tldraw/tldraw";
import { jsPDF } from "jspdf";
import styled from "styled-components";

const ExportButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 100px;
  background: linear-gradient(135deg, #64b5f6 0%, #4db6ac 100%);
  color: white;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Inter", sans-serif;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 5px rgba(77, 182, 172, 0.3);
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function ExportPdfButton({ editor }) {
  const [exportProgress, setExportProgress] = useState(null);

  const handleExport = async () => {
    if (!editor) {
      console.error("Editor instance is not available");
      return;
    }

    setExportProgress(0);
    try {
      // Get all edit buttons inside the tldraw editor container
      const elementsToHide = editor
        .getContainer()
        .querySelectorAll("[data-export-ignore='true']");

      // Store original display values and hide elements
      const originalDisplays = Array.from(elementsToHide).map((el) => ({
        element: el,
        display: el.style.display || "",
      }));

      // Hide all elements
      elementsToHide.forEach((el) => {
        el.style.visibility = "hidden"; // Using visibility instead of display
      });

      // Small delay to ensure DOM updates
      await new Promise((resolve) => requestAnimationFrame(resolve));

      // Get fixed dimensions
      const pageWidth = 3456;
      const pageHeight = 2304;

      // Export everything within these bounds
      const blob = await exportToBlob({
        editor,
        format: "png",
        scale: 2,
        background: true,
        padding: 0,
        preserveAspectRatio: true,
        bounds: {
          x: 0,
          y: 0,
          width: pageWidth,
          height: pageHeight,
        },
      });

      // Restore visibility to all elements
      originalDisplays.forEach(({ element, display }) => {
        element.style.visibility = "visible";
      });

      // Create PDF with the same dimensions
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: [pageWidth, pageHeight],
      });

      // Add the image to the PDF
      const imgData = await blobToBase64(blob);
      pdf.addImage(imgData, "PNG", 0, 0, pageWidth, pageHeight);

      // Save the PDF
      pdf.save("poster.pdf");

      setExportProgress(100);
    } catch (error) {
      console.error("Error exporting PDF:", error);
      alert("Failed to export PDF. Please try again.");
    } finally {
      setExportProgress(null);
    }
  };

  return (
    <ExportButton onClick={handleExport}>
      <svg
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      >
        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
        <polyline points="7 10 12 15 17 10" />
        <line x1="12" y1="15" x2="12" y2="3" />
      </svg>
      {exportProgress !== null
        ? `Exporting... ${Math.round(exportProgress)}%`
        : "Export PDF"}
    </ExportButton>
  );
}
