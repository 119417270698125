import {
  TldrawUiContextProvider,
  TldrawUiMenuGroup,
  TldrawUiMenuItem,
  useEditor,
  DefaultColorStyle,
} from "@tldraw/tldraw";

const CustomContextMenuContent = ({ mouseX, mouseY }) => {
  const editor = useEditor();

  // Shape-specific action handlers
  const handleDelete = () => {
    const selectedShapes = editor.getSelectedShapes();
    editor.deleteShapes(selectedShapes.map((shape) => shape.id));
  };

  const handleDuplicate = () => {
    const selectedShapes = editor.getSelectedShapes();
    editor.duplicateShapes(selectedShapes.map((shape) => shape.id));
  };

  const handleBringToFront = () => {
    const selectedShapes = editor.getSelectedShapes();
    editor.bringToFront(selectedShapes.map((shape) => shape.id));
  };

  const handleSendToBack = () => {
    const selectedShapes = editor.getSelectedShapes();
    editor.sendToBack(selectedShapes.map((shape) => shape.id));
  };

  const handleCustomImageInsert = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = async (e) => {
      const file = e.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async () => {
          const dataUrl = reader.result;
          const img = new Image();
          img.src = dataUrl;

          img.onload = () => {
            const { naturalWidth, naturalHeight } = img;
            const aspectRatio = naturalWidth / naturalHeight;

            // Default size for new images
            const defaultWidth = 300;
            const height = defaultWidth / aspectRatio;

            // Create asset
            const newAssetId = `asset:${Date.now()}`;
            editor.createAssets([
              {
                id: newAssetId,
                type: "image",
                typeName: "asset",
                props: {
                  name: file.name,
                  src: dataUrl,
                  w: defaultWidth,
                  h: height,
                  mimeType: file.type,
                  isAnimated: false,
                },
                meta: null,
              },
            ]);

            // Create custom image shape
            editor.createShape({
              id: `shape:${Date.now()}`,
              type: "custom-image",
              x: 200 - defaultWidth / 2,
              y: 200 - height / 2,
              props: {
                assetId: newAssetId,
                w: defaultWidth,
                h: height,
              },
            });
          };
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  // Canvas-specific action to add text shape
  const addCustomTextShape = () => {
    const shapeId = `shape::custom-text-${Date.now()}`;
    const x = mouseX || 50; // Use mouseX or default to 100 if not provided
    const y = mouseY || 50; // Use mouseY or default to 100 if not provided

    editor.createShape({
      id: shapeId,
      type: "custom-text",
      x: x,
      y: y,
      parentId: editor.currentPageId,
      props: {
        text: "Insert text here",
        fontSize: "16px", // Smaller default font size
        color: DefaultColorStyle.defaultValue,
        align: "middle",
        autoSize: false,
        w: 200, // Adjusted width for smaller font size
        font: "serif",
        bold: false,
        italic: false,
      },
    });
  };

  // Check if there are selected shapes
  const hasSelection = editor.getSelectedShapes().length > 0;

  return (
    <TldrawUiContextProvider>
      <TldrawUiMenuGroup>
        {hasSelection ? (
          // Shape-specific menu items
          <>
            <TldrawUiMenuItem
              id="delete"
              label="Delete"
              onSelect={handleDelete}
            />
            <TldrawUiMenuItem
              id="duplicate"
              label="Duplicate"
              onSelect={handleDuplicate}
            />
            <TldrawUiMenuItem
              id="bring-to-front"
              label="Bring to Front"
              onSelect={handleBringToFront}
            />
            <TldrawUiMenuItem
              id="send-to-back"
              label="Send to Back"
              onSelect={handleSendToBack}
            />
          </>
        ) : (
          // Canvas-specific menu items
          <>
            <TldrawUiMenuItem
              id="add-text-shape"
              label="Insert text"
              onSelect={addCustomTextShape}
            />
            <TldrawUiMenuItem
              id="add-image-shape"
              label="Insert image"
              onSelect={handleCustomImageInsert}
            />
          </>
        )}
      </TldrawUiMenuGroup>
    </TldrawUiContextProvider>
  );
};

export default CustomContextMenuContent;
