import React, { useCallback, useState } from "react";
import {
  Tldraw,
  DefaultColorStyle,
  ContextMenu,
  // loadSnapshot
} from "@tldraw/tldraw";
import "@tldraw/tldraw/tldraw.css";
import styled from "styled-components";
import CustomGrid from "./CustomGrid";
import { CustomTool } from "./utils/customTool";
import {
  SnappingUtil,
  CustomTextUtil,
  CustomImageUtil,
} from "./utils/customUtil";
import snapshot from "./utils/snapshot.json";
import { components } from "./ui-overrides";
import { ExportPdfButton } from "./ExportPDFButton";
import CustomContextMenuContent from "./CustomContextMenuContent";
import CustomMainMenu from "./CustomMainMenu";
import {
  containerId,
  titleId,
  authorsId,
  preSectionTitleId,
  preTextId,
  preImageId,
  referencesId,
} from "../constants";

const EditorContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`;

const GridControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  background-color: white;
  width: 100%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  font-family: "Inter", sans-serif;
`;

const ControlGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const GridLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 400;
  color: #34495e;
  opacity: 0.8;

  input[type="range"] {
    width: 120px;
    height: 4px;
    margin: 0;
    background: #e0e0e0;
    border-radius: 2px;
    -webkit-appearance: none;
    position: relative;
    cursor: pointer;

    /* Webkit (Chrome, Safari, newer Edge) */
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #64b5f6;
      cursor: pointer;
      transition: all 0.2s ease;
      margin-top: -6px; /* Centers the thumb */

      &:hover {
        transform: scale(1.1);
      }
    }

    /* Firefox */
    &::-moz-range-thumb {
      width: 16px;
      height: 16px;
      border: none;
      border-radius: 50%;
      background: #64b5f6;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        transform: scale(1.1);
      }
    }

    /* Edge and IE */
    &::-ms-thumb {
      width: 16px;
      height: 16px;
      border: none;
      border-radius: 50%;
      background: #64b5f6;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        transform: scale(1.1);
      }
    }

    /* Track styles */
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      background: #e0e0e0;
      border-radius: 2px;
      cursor: pointer;
    }

    &::-moz-range-track {
      width: 100%;
      height: 4px;
      background: #e0e0e0;
      border-radius: 2px;
      cursor: pointer;
    }

    &::-ms-track {
      width: 100%;
      height: 4px;
      background: #e0e0e0;
      border-radius: 2px;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  span {
    min-width: 45px;
  }
`;

const FeedbackLink = styled.a`
  color: #34495e;
  text-decoration: none;
  font-size: 14px;
  opacity: 0.8;
  font-family: "Inter", sans-serif;
  padding: 6px 12px;
  border-radius: 6px;
  transition: all 0.2s ease;

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
`;

const customTools = [CustomTool];

function stripMarkdown(text) {
  return (
    text
      // Headers
      .replace(/^#{1,6}\s/gm, "") // Remove #, ##, ###, etc.

      // Bold
      .replace(/\*\*(.*?)\*\*/g, "$1") // **bold**
      .replace(/__(.*?)__/g, "$1") // __bold__

      // Italic
      .replace(/\*(.*?)\*/g, "$1") // *italic*
      .replace(/_(.*?)_/g, "$1") // _italic_

      // Lists
      .replace(/^[\*\-+]\s/gm, "") // Unordered lists
      .replace(/^\d+\.\s/gm, "") // Ordered lists

      // Links
      .replace(/\[([^\]]+)\]\([^\)]+\)/g, "$1") // [text](url)

      // Code
      .replace(/`([^`]+)`/g, "$1") // `code`

      // Blockquotes
      .replace(/^\>\s/gm, "") // > quote

      // Remove extra whitespace
      .trim()
  );
}

const PosterEditor = ({ poster }) => {
  const [gridSize, setGridSize] = useState(20);
  const [editorInstance, setEditorInstance] = useState(null);
  const [posterState, setPosterState] = useState(null);

  const posterData = poster.data;
  const resume = poster.resume;

  const calculateTextHeight = (text, fontSize, width) => {
    const testElement = document.createElement("canvas").getContext("2d");
    testElement.font = `${fontSize}px serif`;

    const lines = text.split("\n");
    let height = 0;

    lines.forEach((line) => {
      const { width: lineWidth } = testElement.measureText(line);
      const lineCount = Math.ceil(lineWidth / width);
      height += lineCount * fontSize * 1.2; // Line height multiplier for spacing
    });

    return height;
  };

  const handleMount = useCallback(
    (editor) => {
      setEditorInstance(editor);
      if (resume) {
        console.log("loading snapshot");
        setPosterState(posterData);
        // loadSnapshot(editor.store, {document: posterData});
        return;
      } else {
        setPosterState(snapshot);
      }

      editor.user.updateUserPreferences({
        isSnapMode: true,
      });
      editor.updateInstanceState({ isGridMode: false });

      if (!posterData) return;

      const pageWidth = 3456;
      const pageHeight = 2304;
      const margin = 20;
      const columnWidth = (pageWidth - margin * 2) / 3;

      editor.createShape({
        id: containerId,
        type: "geo",
        x: 0,
        y: 0,
        props: {
          w: pageWidth,
          h: pageHeight,
          geo: "rectangle",
        },
      });

      const wLogos = 200;

      editor.createShape({
        type: "custom-image",
        id: "shape::conference_logo.png",
        x: margin,
        y: margin,
        parentId: containerId,
        props: {
          w: wLogos,
          h: wLogos,
        },
      });

      editor.createShape({
        type: "custom-image",
        id: "shape::affiliation_logo.png",
        x: pageWidth - margin - wLogos,
        y: margin,
        parentId: containerId,
        props: {
          w: wLogos,
          h: wLogos,
        },
      });

      const shapeIds = [containerId];

      const titleText = posterData.title;
      const titleFontSize = 48;
      const titleWidth = titleText.length * (titleFontSize * 0.6);
      const titleX = (pageWidth - titleWidth) / 2;

      editor.createShape({
        id: titleId,
        type: "custom-text",
        x: titleX,
        y: margin,
        parentId: containerId,
        props: {
          text: stripMarkdown(titleText),
          fontSize: "48px",
          color: DefaultColorStyle.defaultValue,
          align: "middle",
          autoSize: false,
          w: titleWidth,
          font: "serif",
          bold: true,
          italic: false,
        },
      });
      shapeIds.push(titleId);

      const authorsText = posterData.authors.join(", ");
      const authorsFontSize = 34;
      const authorsWidth = authorsText.length * (authorsFontSize * 0.6);
      const authorsX = (pageWidth - authorsWidth) / 2;

      editor.createShape({
        id: authorsId,
        type: "custom-text",
        x: authorsX,
        y: margin + 60,
        parentId: containerId,
        props: {
          text: stripMarkdown(authorsText),
          fontSize: "34px",
          color: DefaultColorStyle.defaultValue,
          align: "middle",
          autoSize: false,
          w: authorsWidth,
          font: "serif",
          textAlign: "middle",
        },
      });
      shapeIds.push(authorsId);

      posterData.sections.forEach((section, sectionIndex) => {
        const sectionShapeIds = [];
        let imagesToLoadInSection = 0; // Track images within each section

        // Calculate x based on sectionIndex for column positioning
        const x = margin + sectionIndex * (columnWidth + margin);
        let y = margin + wLogos;

        // Create section title
        const sectionTitleId = `${preSectionTitleId}${sectionIndex}`;
        editor.createShape({
          id: sectionTitleId,
          type: "custom-text",
          x: x,
          y: y,
          parentId: containerId,
          props: {
            text: `**${
              section.section_title.charAt(0).toUpperCase() +
              section.section_title.slice(1)
            }**`,
            fontSize: "34px",
            w: columnWidth,
            color: DefaultColorStyle.defaultValue,
            align: "start",
            autoSize: false,
            font: "serif",
            bold: true,
            italic: false,
          },
        });
        sectionShapeIds.push(sectionTitleId); // Add title to section group
        y += 60;

        // Define fixed spacing and block height
        const fixedSpacing = 10;
        const numBlocks = section.content.length;
        const maxContentHeight = pageHeight - margin * 2 - 200;
        const blockHeight = (maxContentHeight - 60) / numBlocks;

        section.content.forEach((block, blockIndex) => {
          const blockShapeIds = [];
          let yOffset = y;

          block.forEach((contentItem, itemIndex) => {
            let shapeId;

            if (contentItem.type === "markdown") {
              const fontSize = 24;
              const textHeight = calculateTextHeight(
                contentItem.content,
                fontSize,
                columnWidth - 2 * margin
              );

              shapeId = `${preTextId}${sectionIndex}-${blockIndex}-${itemIndex}`;
              editor.createShape({
                id: shapeId,
                type: "custom-text",
                x: x,
                y: yOffset,
                parentId: containerId,
                props: {
                  text: stripMarkdown(contentItem.content),
                  fontSize: `${fontSize}px`,
                  w: columnWidth - 2 * margin,
                  h: textHeight + 100,
                  font: "serif",
                  autoSize: false,
                  color: DefaultColorStyle.defaultValue,
                  align: "start",
                },
              });
              blockShapeIds.push(shapeId);
              sectionShapeIds.push(shapeId);

              yOffset += textHeight + fixedSpacing;
            } else if (contentItem.type === "image") {
              imagesToLoadInSection++; // Increment for each image in the section
              const assetId = `asset:${preImageId}${sectionIndex}-${blockIndex}-${itemIndex}`;
              const shapeId = `shape:${preImageId}${sectionIndex}-${blockIndex}-${itemIndex}`;
              const dataUrl = contentItem.content;

              const maxWidth = columnWidth - 2 * margin;
              const maxHeight = blockHeight * 0.4;

              const img = new Image();
              img.src = dataUrl;
              img.onload = () => {
                const { naturalWidth, naturalHeight } = img;
                const aspectRatio = naturalWidth / naturalHeight;
                let imageWidth = maxWidth;
                let imageHeight = maxWidth / aspectRatio;

                if (imageHeight > maxHeight) {
                  imageHeight = maxHeight;
                  imageWidth = maxHeight * aspectRatio;
                }

                editor.createAssets([
                  {
                    id: assetId,
                    type: "image",
                    typeName: "asset",
                    props: {
                      name: "uploaded-image.png",
                      src: dataUrl,
                      w: imageWidth,
                      h: imageHeight,
                      mimeType: "image/png",
                      isAnimated: false,
                    },
                    meta: null,
                  },
                ]);

                editor.createShape({
                  id: shapeId,
                  type: "custom-image",
                  x: x,
                  y: yOffset,
                  parentId: containerId,
                  props: {
                    assetId: assetId,
                    w: imageWidth,
                    h: imageHeight,
                  },
                });

                blockShapeIds.push(shapeId);
                yOffset += imageHeight + fixedSpacing;

                imagesToLoadInSection--; // Decrement after each image loads
                if (imagesToLoadInSection === 0 && blockShapeIds.length > 0) {
                  editor.groupShapes(blockShapeIds); // Group block once all images load
                }
              };
            }
          });

          sectionShapeIds.push(...blockShapeIds); // Add block shapes to section
          y += blockHeight;
        });

        // Group the entire section after all blocks are added
        if (sectionShapeIds.length > 0 && imagesToLoadInSection === 0) {
          const sectionGroupId = `section-group-${sectionIndex}`;
          editor.groupShapes(sectionShapeIds, { id: sectionGroupId });
          shapeIds.push(sectionGroupId); // Add each section group to main shape list for container
        }
      });

      // After all sections are grouped, group the entire container content
      if (shapeIds.length > 0) {
        editor.groupShapes(shapeIds);
      }

      const fontSize = 24; // Adjust if needed
      const textHeight = calculateTextHeight(
        stripMarkdown("References:\n" + posterData.references.join("\n")),
        fontSize,
        columnWidth - 2 * margin
      );
      editor.createShape({
        id: referencesId,
        type: "custom-text",
        x: margin,
        y: pageHeight - textHeight,
        parentId: containerId,
        props: {
          text: stripMarkdown(
            "References:\n" + posterData.references.join("\n")
          ),
          fontSize: `${fontSize}px`,
          w: pageWidth - margin * 2,
          h: textHeight,
          font: "serif",
          color: DefaultColorStyle.defaultValue,
          align: "start",
          autoSize: false,
        },
      });
      shapeIds.push(referencesId);

      editor.groupShapes(shapeIds);
      editor.zoomToFit();
    },
    [posterData, resume]
  );
  const customShapes = [SnappingUtil, CustomTextUtil, CustomImageUtil];

  const handleKeyDown = (e) => {
    if ((e.key === "Backspace" || e.key === "Delete") && editorInstance) {
      const selectedShapes = editorInstance.getSelectedShapes();

      // Check if any selected shape is an image
      const hasImageShape = selectedShapes.some(
        (shape) => shape.type === "image"
      );

      if (hasImageShape) {
        e.preventDefault();
        editorInstance.deleteShapes(selectedShapes.map((shape) => shape.id));
      }
    }
  };

  return (
    <EditorContainer id="poster" className="poster-container">
      <GridControl>
      <ControlGroup>
        <GridLabel>
          Grid Size:
          <input
            type="range"
            min="1"
            max="20"
            value={gridSize}
            onChange={(e) => setGridSize(Number(e.target.value))}
          />
          <span>{gridSize}px</span>
          {editorInstance && (
            <GridLabel>
              Show grid:
              <input
                type="checkbox"
                checked={editorInstance.isGridMode}
                onChange={(e) => editorInstance.updateInstanceState({ isGridMode: e.target.checked })}
              />
            </GridLabel>
        )}
        </GridLabel>
        {editorInstance && (
            <ExportPdfButton editor={editorInstance} />
        )}
      </ControlGroup>
        <FeedbackLink href="mailto:postertopaper@gmail.com?subject=Paper2Poster">
          Any feedback? Reach out!
        </FeedbackLink>
      </GridControl>
      <div
        className="tldraw__editor"
        style={{ width: "100%", height: "100%" }}
        onKeyDown={handleKeyDown}
        onDoubleClick={() => { if (editorInstance) {
          editorInstance.cancel();
        } }}
      >
        <Tldraw
          shapeUtils={customShapes}
          tools={customTools}
          onMount={handleMount}
          snapshot={posterState}
          components={{
            ...components,
            Grid: (props) => <CustomGrid {...props} gridSize={gridSize} />,
            MainMenu: CustomMainMenu,
            ActionsMenu: null,
            DebugMenu: null,
            HelpMenu: null,
            KeyboardShortcutsDialog: null,
            // NavigationPanel: null,
            PageMenu: null,
            Toolbar: null,
            StylePanel: null,
            ContextMenu: (props) => (
              <ContextMenu {...props}>
                <CustomContextMenuContent />
              </ContextMenu>
            ),
          }}
        />
      </div>
      {/* Overlay div to capture and cancel double-clicks */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 10,
          pointerEvents: "none", // Allow double-clicks to be intercepted here
        }}
        onDoubleClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (editorInstance) {
            editorInstance.cancel(); // Cancel any ongoing actions if needed
          }
        }}
      />
    </EditorContainer>
  );
};

export default PosterEditor;
